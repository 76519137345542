<template>
  <v-card flat >
    <v-container>
      <v-row justify="center">
        <p class="text-h5">{{ $t("Backup settings") }}</p>
      </v-row>
      <v-row>
        <v-col cols="12" align="center">
          <v-btn
          dark
          large
          color="#196619"
          :loading="loading"
          @click="proceed"
          >
            {{$t('Procced Backup')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
    name: "BackUp",
    data () {
      return {
        loading: false
      }
    },
    methods: {
      async proceed() {
       
        try {
          this.loading = true;
          await this.$http.get("/dump_station_setting");
          this.$emit("alertMsg", {
            type: "success",
            text: "Backup --> OK",
          })
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.loading = false;
          console.log("error back",e);
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n" + e.response?.data?.error,
          });
        } finally {
          this.loading = false
        }
      
      }
    }

}
</script>


<style >



</style>
